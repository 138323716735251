@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
   --primary-white: #ffffff;
   --primary-light-grey: #f4f4f4;
   --primary-grey: #ccd1d9;
   --primary-dark-grey: #666666;
   --primary-orange: #f9ab00;
   --primary-dark: rgb(33,37,41);
   --primary-red: #ef4035;
   --primary-hover-red: #f33501;
   --primary-black: #000000;
   --primary-transparent-black: rgba(0,0,0,0.7);
   --primary-green: green;
   --primary-blue-lagoon: #318ba2;


}

.app-style {
   background-color: #ffffff;
   background-color: var(--primary-white);
}

body {
   font-family: "Roboto-Condensed", sans-serif !important;
   font-family: "Noto Sans Lao", sans-serif !important;
}

/************************ NAVBAR ************************************/

nav {
   z-index: 2;
}
.logo {
   width: 16.25rem;
}

/*--------- MENU LINKS ---------*/
nav a.nav-link {
   letter-spacing: 0.0375rem;
   cursor: pointer;
}

nav ul li {
   text-transform: uppercase;
}

.navbar-nav > li > a {
   margin-right: 0.75rem;
   margin-left: 0.75rem;
   color: #ffffff !important;
   color: var(--primary-white) !important;
   border-top: 0.1875rem solid rgb(33,37,41);
   border-top: 0.1875rem solid var(--primary-dark);
}


.navbar-brand {
   max-width: 17.25rem;
   padding: 0 !important;
   height: 4rem !important;
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
   border-top: 0.1875rem solid #318ba2;
   border-top: 0.1875rem solid var(--primary-blue-lagoon);
}

.navbar-nav > li > a:hover {
   border-top: 00.1875rem solid #318ba2;
   border-top: 00.1875rem solid var(--primary-blue-lagoon);
}



/*********************** HEADER ************************************/

.header-wraper {
   position: relative;
   background: url(/static/media/wall-and-laptop-background.f9e274f4.jpg) no-repeat;
   background-size: cover;
   background-position: center;
   height: 100vh;
}

.main-info {
   display: flex;
   position:absolute;
   top:0;
   left:0;
   right:0;
   bottom: 0;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   z-index: 1;
   font-family: "noto sans lao", sans-serif;
}
.main-info h1 {
   color: #f9ab00;
   color: var(--primary-orange);
   text-transform: uppercase;
   cursor: pointer;
}
.btn-main-offer {
   cursor: pointer;
}

/********************* TYPED TEXT ********************************/

.typewriter-pro {
   font-size: 2rem;
   color: #ffffff;
   color: var(--primary-white);
}


/******************** HEADER MAIN OFFER BUTTON ******************/
.btn-main-offer {
   border: 1px solid #ef4035;
   border: 1px solid var(--primary-red);
   text-transform: uppercase;
   border-radius: 0;
   padding: 0.625rem 0.75rem 0.75rem 0.75rem;
   color: #ffffff;
   color: var(--primary-white);
   margin: 2rem;
   text-decoration: none;
}

.btn-main-offer:hover {
   text-decoration: none;
   background-color: #f33501;
   background-color: var(--primary-hover-red);
   color: #ffffff;
   color: var(--primary-white);
   transition: .2s ease-in-out;
}

/******************** ABOUT ME **************************/
.profile-img {
   width: 16rem;
   border-radius: 50%;
   padding: .3rem;
   border: 1px solid #f9ab00;
   border: 1px solid var(--primary-orange);
}
.about-heading {
   color: #000000;
   color: var(--primary-black);
   text-transform: uppercase;
}
.about-text {
   text-align: left;
}

.photo-wrap {
      justify-content: center;
   }

/******************** SERVICES **************************/
.services {
   text-align: center;
   padding: 2rem;
   background: #f4f4f4;
   background: var(--primary-light-grey);
}
.services h1 {
   color: rgb(33,37,41);
   color: var(--primary-dark);
   text-transform: uppercase;
}
.services p {
   text-align: left;
}

.services .circle {
   position: relative;
   margin: 0.375rem auto;
   background: #f9ab00;
   background: var(--primary-orange);
   border-radius: 50%;
   width: 5.125rem;
   height: 5.125rem;
}
.services .icon {
   font-size: 1.4rem;
   color: #ffffff;
   color: var(--primary-white);
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
}
.services .box {
   height: 16rem;
   border-bottom: 0.3125rem solid #f9ab00;
   border-bottom: 0.3125rem solid var(--primary-orange);
   padding: 0.625rem;
   margin-bottom: 0.875rem;
   background: #ffffff;
   background: var(--primary-white);
   transition: 0.3s ease-in-out;
}
.services .box:hover {
   background: #f9ab00;
   background: var(--primary-orange);
   border-bottom: 0.3125rem solid rgb(33,37,41);
   border-bottom: 0.3125rem solid var(--primary-dark);
}
.services .box:hover .icon {
   color: rgb(33,37,41);
   color: var(--primary-dark);
}
.services .box:hover .circle {
   background: #ffffff;
   background: var(--primary-white);
}
.services .box:hover p {
   color: #ffffff;
   color: var(--primary-white);
}

/******************** EXPERIENCE **************************/
.experience {
   padding: 0.7rem;
}
.experience-wrapper {
   position:relative;
   padding: 3.125rem 0;
   overflow: hidden;
}
.experience h1 {
   text-transform: uppercase;
   color: #000000;
   color: var(--primary-black);
}
.experience-wrapper::before {
   position: absolute;
   content: "";
   top: 0;
   left: 50%;
   margin-left: -0.0625rem;
   width: 0.125rem;
   height: 100%;
   background: #ccd1d9;
   background: var(--primary-grey);
   z-index: 1;
}
.timeline-block {
   width: calc(50% + 0.5rem);
   display: flex;
   justify-content: space-between;
   clear: both;
}
.timeline-block-right {
   float: right;
}
.timeline-block-left {
   float: left;
   direction: rtl;
}
.marker {
   width: 1rem;
   height: 1rem;
   border-radius: 50%;
   border: 0.3125rem solid rgb(33,37,41);
   border: 0.3125rem solid var(--primary-dark);
   background: #f9ab00;
   background: var(--primary-orange);
   margin-top: 0.625rem;
   z-index: 3;
}
.timeline-content {
   width: 95%;
   padding: 0 0.9375rem;
   color: #666666;
   color: var(--primary-dark-grey);
   direction: ltr;
}
.timeline-content h3{
   margin-top: 0.3125rem;
   color: #ef4035;
   color: var(--primary-red);
   font-weight: 900;
}

/******************** PORTFOLIO **************************/

.portfolio-wrapper {
   background: #f4f4f4;
   background: var(--primary-light-grey);
   padding: 3rem 0;
}
.portfolio-wrapper h1 {
   color: #000000;
   color: var(--primary-black);
}
.portfolio-image {
   width: 15rem;
}
.portfolio-image-box {
   position: relative;
   margin: 0.5rem;
}


/*************************** TESTIMONIALS ****************************/
.testimonials {
   background-image: url(/static/media/testimonials-bg.75612be7.jpg);
   background-size: cover;
   background-color: #000000;
   background-color: var(--primary-black);
   text-align: center;
   padding-bottom: 4rem;
   margin-bottom: 0;
   width: 100%;
}
.testimonials h1 {
   color: #ffffff;
   color: var(--primary-white);
   padding: 3rem 0;
   text-transform: uppercase;
}

.testimonials-content {
   text-align: center;
   max-width: 53.125rem;
   margin: 0 auto 2rem auto;
   max-width: rgba(0,0,0,0.7);
   max-width: var(--primary-transparent-black);
}
.myCarousel {
   color: #ffffff;
   color: var(--primary-white);
   background: transparent;
   margin-top: -6%;
   width: 54%;
   margin-left: auto;
   margin-right: auto;
   padding-top: 6%;
   padding-bottom: 8%;
   padding-left: 5%;
   padding-right: 5%;
   height: 17.875rem;
}
.myCarousel h3 {
   color: #f9ab00;
   color: var(--primary-orange);
   font-weight: 600;
   text-transform: uppercase;
}
.myCarousel p {
   color: #ccd1d9;
   color: var(--primary-grey);
}
.carousel .slide img {
   width: 10rem !important;
   border-radius: 50%;
}
.carousel .slide {
   background: transparent !important;
   height: 100% !important;
   padding-top: 2rem;
}
.carousel-root {
   margin: auto !important;
   margin-top: 3% !important;
}

/*************************** CONTACTS COMPONENT ****************************/
.contacts {
   background: rgb(33,37,41);
   background: var(--primary-dark);
   padding: 3rem 0;
}
.contacts h1 {
   color: #ffffff;
   color: var(--primary-white);
   padding: 1.3rem;
   text-transform: uppercase;
}
.contacts p {
   color: #f4f4f4;
   color: var(--primary-light-grey);
   font-size: 1.2rem;
}

/*************************** INPUTS ****************************/
.contacts input, .contacts textarea {
   width: 100%;
   box-sizing: border-box;
   background: none;
   resize: none;
   border: 0;
   border-bottom: 0.125rem solid #666666;
   border-bottom: 0.125rem solid var(--primary-dark-grey);
   border-radius: 0;
   color: #f9ab00;
   color: var(--primary-orange);
   outline: 0 !important;
   box-shadow: none !important;
   top: 0%;
   margin-bottom: 1rem;
   padding-left: 0 !important;
}
.contacts .container {
   padding: 4rem 0;
}
.contacts textarea {
   height: 12.5rem !important;
}
.contact-btn {
   margin: 1rem 0 0 0;
   width: 100%;
   background: none;
}
.line {
   position: relative;
   top: -0.8rem;
   width: 0;
   height: 0.125rem;
   background: #f9ab00;
   background: var(--primary-orange);
   display: inline-block;
}
input:focus+.line, textarea:focus+.line {
   width: 100%;
   transition: width 0.5s ease-in-out;
}
input.form-control {
   margin: -0.3rem 0;
}
textarea.form-control {
   margin: -0.3rem 0;
}
.error-message {
   color: #ef4035;
   color: var(--primary-red);
   text-align: left;
}
.success-message {
   font-weight: 900;
   color: green;
   color: var(--primary-green);
   background: #f9ab00;
   background: var(--primary-orange);
}

/*-------------------------- MEDIA ---------------------------*/
@media(max-width: 768px) {
   .contacts {
      overflow-x: hidden;
   }
   .contacts .container {
      padding: 0 1rem;
   }
   .contacts p {
      padding: 0 0.4rem;
   }
}

/************************ FOOTER COMPONENT ****************/
.footer {
   background: #000000;
   background: var(--primary-black);
   color: #666666;
   color: var(--primary-dark-grey);
   padding: 3rem;
}
.footer a {
   text-decoration: none;
   color: #666666;
   color: var(--primary-dark-grey);
}
.footer a:hover {
   text-decoration: none;
   color: #f9ab00;
   color: var(--primary-orange);
   cursor: pointer;
}
.footer p {
   margin-bottom: 0;
}

/********************************** RESPONSIVE **************************/
@media(min-width: 360px) {
   .navbar-brand {
   height: 8rem !important;
}
   .logo {
      width: 12rem;
   }
   .svg-inline--fa {
      height: 3rem;
   }
   nav a.nav-link {
      font-size: 2rem;
   }
   .main-info h1 {
      font-size: 3.375rem;
   }
   .typewriter-pro {
      font-size: 3rem;
   }
   .btn-main-offer {
      font-size: 2rem;
   }
   /*--- ABOUT ME ---*/
   .profile-img {
      width: 25rem;

   }
   .about-heading {
      margin-top: 3rem;
      margin-bottom: 3rem;
      text-align: center;
   }
   .photo-wrap {
      display: flex;
      justify-content: center;
   }
   .about-text {
      font-size: 1.8rem;
   }

   /*--- SERVICES ---*/
   .services h3 {
      font-size: 2.5rem;
   }
   .services p {
      font-size: 2.2rem;
   }
   .services .box {
      height: 30rem;
      padding: 0.625rem;
      margin-bottom: 1.875rem;
      background: #ffffff;
      background: var(--primary-white);
   }

   /*--- EXPERIENCE ---*/
   
   .experience .container {
      padding-left: 0;
   }
   .experience-wrapper {
      padding-left: 0;
   }
   .experience-wrapper::before {
      left: 0.5rem;
      margin-bottom: 1.875rem;
   }
   .experience p {
      font-size: 1.8rem;
   }
   .timeline-block-right {
      float: none;
      width: 100%;
   }
   .timeline-block-left {
      float: none;
      direction: ltr;
      width: 100%;
   }

   /*--- PORTFOLIO ---*/

   .portfolio-image {
      width: 30rem;
      padding: 2rem;
      margin-bottom: 2rem;
   }

   /*--- TESTIMONIALS ---*/
   .myCarousel {
      margin-bottom: 10rem;
   }
   .myCarousel h3 {
      font-size: 2.5rem;
   }
   .myCarousel p {
      font-size: 2.5rem;
   }

    /*--- CONTACTS ---*/
    .contacts p {
       font-size: 2rem;
    }
    .contacts .form-control {
       font-size: 2rem;
    }

   /*--- CONTACTS ---*/
   .footer p {
      font-size: 2rem;
   }
   .footer a {
      font-size: 2rem;
   }
}
@media(min-width: 412px) {
   .experience h4 {
      font-size: 1.8rem;
      font-weight: 700;
   }
}


@media(min-width: 540px) {
   .about-text {
      font-size: 1.8rem;
   }
}

@media(min-width: 768px) {
   .about-text {
      font-size: 1.8rem;
   }
   /*--- SERVICES ---*/
   .services h3 {
      font-size: 2.5rem;
   }
   .services p {
      font-size: 1.8rem;
   }
   .services .box {
      /* height: 25rem; */
      padding: 0.625rem;
      margin-bottom: 1.875rem;
      background: #ffffff;
      background: var(--primary-white);
   }
   .myCarousel h3 {
      font-size: 1.8rem;
   }
   .myCarousel p {
      font-size: 1.5rem;
   }
   .contacts p {
       font-size: 1.5rem;
    }
    /*--- CONTACTS ---*/
   .footer p {
      font-size: 1.5rem;
   }
   .footer a {
      font-size: 1.5rem;
   }
   /*--- CONTACTS ---*/
    .contacts p {
       font-size: 1.5rem;
    }
    .contacts .form-control {
       font-size: 1.5rem;
    }
   
}

@media(min-width: 1024) {
   .portfolio-image {
      width: 50rem;
      border: 1px solid #f9ab00;
      border: 1px solid var(--primary-orange);
      padding: 0 0.5rem;
      margin-left: -14rem;
   }

}

@media(min-width: 990px) {
   .navbar-brand {
      max-width: 17.25rem;
      padding: 0 !important;
      height: 6rem !important;
}
   .logo {
      width: 8rem;
   }
   nav a.nav-link {
      font-size: 1rem;
   }
   .main-info h1 {
      font-size: 2.375rem;
   }
   .typewriter-pro {
      font-size: 1.5rem;
   }
   .btn-main-offer {
      font-size: 1rem;
   }
   .profile-img {
      width: 18rem;

   }
   .about-heading {
      margin-top: 2rem;
      text-align: center;
   }
   .about-text {
      font-size: 1.4rem;
   }

   /*--- SERVICES ---*/
   .services h3 {
      font-size: 1.5rem;
      font-weight: 700;
   }
   .services p {
      font-size: 1.2rem;
   }
   .services .box {
      height: 30rem;
   }
   /*--- EXPERIENCE ---*/
   .experience {
      padding: 0.7rem;
   }
   .experience-wrapper {
      position:relative;
      padding: 3.125rem 0;
      overflow: hidden;
   }
   .experience h1 {
      text-transform: uppercase;
   }
   .experience h4 {
      font-weight: 600;
   }
   .experience p {
      font-size: 1.2rem;
   }
   .experience-wrapper::before {
      position: absolute;
      content: "";
      top: 0;
      left: 50%;
      margin-left: -0.3625rem;
      width: 0.125rem;
      height: 100%;
      background: #ccd1d9;
      background: var(--primary-grey);
      z-index: 1;
   }
   .timeline-block {
      width: calc(50% + 0.5rem);
      display: flex;
      justify-content: space-between;
      clear: both;
   }
   .timeline-block-right {
      float: right;
   }
   .timeline-block-left {
      float: left;
      direction: rtl;
   }
   .marker {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 0.3125rem solid rgb(33,37,41);
      border: 0.3125rem solid var(--primary-dark);
      background: #f9ab00;
      background: var(--primary-orange);
      margin-top: 0.625rem;
      z-index: 3;
   }
   .timeline-content {
      width: 95%;
      padding: 0 0.9375rem;
      color: #666666;
      color: var(--primary-dark-grey);
      direction: ltr;
   }
   .timeline-content h3{
      margin-top: 0.3125rem;
      color: #ef4035;
      color: var(--primary-red);
      font-weight: 900;
   }

   /*--- PORTFOLIO ---*/

   .portfolio-image {
      width: 15rem;
      padding: 0 0.5rem;
      margin-left: 0rem;
   }

}
